import { createReducer, on } from '@ngrx/store';
import { MerchantModel } from '../../../../models/api/merchant.model';
import { PagingModel } from '../../../../models/auxiliary/paging.model';
import { StateModel } from '../../../../models/auxiliary/state.model';
import {
  getCurrentRebalancingGroupAction,
  getCurrentRebalancingGroupErrorAction,
  getCurrentRebalancingGroupSuccessAction,
  resetCurrentRebalancingGroupAction,
} from './rebalancing.actions';

// FIXME: paginated data in <bp-select> component:
//  we will have to implement virtual scroll with paginated data fetching inside the select component)
//  when implemented, adjust the default limit
const DEFAULT_REBALANCING_GROUP_LIMIT = 100;

export type RebalancingGroupState = StateModel<PagingModel<MerchantModel>> & { data: PagingModel<MerchantModel> };

const INITIAL_STATE: RebalancingGroupState = {
  data: {
    offset: 0,
    limit: DEFAULT_REBALANCING_GROUP_LIMIT,
    totalCount: 0,
    data: [],
  },
  errors: null,
  loading: false,
};

export const rebalancingReducer = createReducer(
  INITIAL_STATE,
  on(getCurrentRebalancingGroupAction, (state) => ({
    ...state,
    errors: null,
    loading: true,
  })),
  on(getCurrentRebalancingGroupSuccessAction, (state, { rebalancingGroupRes }) => {
    return {
      ...state,
      // TODO: if we hit rebalancing group sizes of 100+ merchants (cc FIXME comment above):
      //  - reimplement rebalancing state from "PagingModel<MerchantModel>" to "{ [groupId]: PagingModel<MerchantModel> }" and append paginated data
      data: rebalancingGroupRes,
      loading: false,
    };
  }),
  on(getCurrentRebalancingGroupErrorAction, (state, { errors }) => ({
    ...state,
    errors,
    loading: false,
  })),
  on(resetCurrentRebalancingGroupAction, () => INITIAL_STATE),
);
